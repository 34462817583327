<template>
  <div class="row option align-items-center">
    <div class="col-6">
      <div class="tooltip-box text-end">
        <tooltip-btn :tooltip="option.tooltip"/>
      </div>
      <img :src="$filters.image(option.image, '350x')" class="img-fluid">
    </div>
    <div class="col-6">
      <div class="option__name mb-2">{{ option.title }}</div>
      <select class="form-select" @change="onChange">
        <option v-for="(selectOption, index) in selectOptions" :value="selectOption.value" :selected="selectOption.selected" :key="index">{{ selectOption.value}} {{ selectOption.unit }}</option>
      </select>
    </div>
  </div>
</template>
<script>
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
import TooltipBtn from '@/pages/configurator/common/tooltipBtn.vue'
export default {
  name: 'DimensionOption',
  components: { TooltipBtn },
  props: {
    type: {
      require: true,
      type: String
    },
    option: {
      require: true,
      type: Object
    },
    optionDim: {
      require: true,
      type: Object
    },
    lenghtMax: {
      require: false,
      type: Number,
      default: 0
    }
  },
  setup (props) {
    const store = useStore()
    const selectOptions = computed(() => {
      const values = []
      const maxDim = (props.type === 'length' && props.lenghtMax > 0) ? props.lengthMax : props.optionDim.max
      for (let i = Number.parseInt(props.optionDim.min); i <= Number.parseInt(maxDim); i += props.optionDim.step) {
        values.push({
          value: i,
          label: `${i} ${props.optionDim.unit}`,
          selected: props.optionDim.value === i
        })
      }
      return values
    })
    function onChange (e) {
      store.dispatch('configurator/setDimensionAwnings', {
        type: props.type,
        value: e.target.value
      })
    }
    return {
      selectOptions,
      onChange
    }
  }
}
</script>
